<template>
  <div class="carousel-3d-slide" :style="slideStyle" :class="computedClasses" @click="goTo()">
    <slot :index="index" :isCurrent="isCurrent" :leftIndex="leftIndex" :rightIndex="rightIndex" />
  </div>
</template>

<script>
import '@/css/carousel.scss';

export default {
  name: "slide",
  props: {
    index: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      parent: this.$parent,
      styles: {},
      zIndex: 999,
    };
  },
  computed: {
    // 是否为当前轮播图
    isCurrent() {
      return this.index === this.parent.currentIndex;
    },
    // 当前轮播图为从从中心到左边的第几张轮播图（0 表示从中间往左第 1 张，-1 表示隐藏幻灯片）
    leftIndex() {
      // if (this.parent.oneDirectional && this.getSideIndex(this.parent.leftIndices) > this.parent.currentIndex - 1) {
      //   return -1;
      // }

      return this.getSideIndex(this.parent.leftIndices);
    },
    // 当前轮播图为从从中心到右边的第几张轮播图（0 表示从中间往右第 1 张，-1 表示隐藏幻灯片）
    rightIndex() {
      // if (
      //   this.parent.oneDirectional &&
      //   this.getSideIndex(this.parent.rightIndices) > this.parent.total - this.parent.currentIndex - 2
      // )
      //   return -1;

      return this.getSideIndex(this.parent.rightIndices);
    },
    // 轮播图样式
    slideStyle() {
      let styles = {};

      if (!this.isCurrent) {
        const lIndex = this.leftIndex;
        const rIndex = this.rightIndex;
        // index 为 -1 的不显示
        if (rIndex >= 0 || lIndex >= 0) {
          styles =
            rIndex >= 0
              ? this.calculatePosition(rIndex, true, this.zIndex)
              : this.calculatePosition(lIndex, false, this.zIndex);
          styles.opacity = 1;
          styles.visibility = "visible";
        }

        // 左右两侧多留出一张轮播图，用于动画过渡
        if (this.parent.hasHiddenSlides) {
          if (this.matchIndex(this.parent.leftOutIndex)) {
            styles = this.calculatePosition(this.parent.leftIndices.length - 1, false, this.zIndex);
          } else if (this.matchIndex(this.parent.rightOutIndex)) {
            styles = this.calculatePosition(this.parent.rightIndices.length - 1, true, this.zIndex);
          }
        }
      }

      return Object.assign(styles, {
        "border-width": this.parent.border + "px", // 边框宽度
        width: this.parent.slideWidth + "px", // 轮播图宽度
        height: this.parent.slideHeight + "px", // 轮播图高度
        // 轮播图动画时间
        transition:
          " transform " +
          this.parent.animationSpeed +
          "ms, " +
          "               opacity " +
          this.parent.animationSpeed +
          "ms, " +
          "               visibility " +
          this.parent.animationSpeed +
          "ms",
      });
    },
    // 轮播图的类名
    computedClasses() {
      return {
        [`left-${this.leftIndex + 1}`]: this.leftIndex >= 0,
        [`right-${this.rightIndex + 1}`]: this.rightIndex >= 0,
        current: this.isCurrent,
      };
    },
  },
  methods: {
    /** 计算当前轮播图在侧边的层级（从 0 开始） */
    getSideIndex(array) {
      let sideIndex = -1; // 表示不显示当前轮播图

      array.forEach((pos, i) => {
        if (this.matchIndex(pos)) {
          sideIndex = i;
        }
      });

      return sideIndex;
    },
    // 根据传入的 index 匹配到对应的轮播图，传入的 index 为负数时，表示从右往左数
    matchIndex(index) {
      return this.index === index;
    },
    /** 计算轮播图的偏移位置 */
    calculatePosition(i, positive, zIndex) {
      const z = !this.parent.disable3d ? parseInt(this.parent.inverseScaling) + (i + 1) * 100 : 0;
      const y = !this.parent.disable3d ? parseInt(this.parent.perspective) : 0;
      const leftRemain =
        this.parent.space === "auto"
          ? parseInt((i + 1) * (this.parent.width / 1.5), 10)
          : parseInt((i + 1) * this.parent.space, 10);
      const transform = positive
        ? "translateX(" + leftRemain + "px) translateZ(-" + z + "px) " + "rotateY(-" + y + "deg)"
        : "translateX(-" + leftRemain + "px) translateZ(-" + z + "px) " + "rotateY(" + y + "deg)";
      const top = this.parent.space === "auto" ? 0 : parseInt((i + 1) * this.parent.space);

      return {
        transform: transform,
        top: top,
        zIndex: zIndex - (Math.abs(i) + 1),
      };
    },
    /** 跳转到指定轮播图 */
    goTo() {
      if (!this.isCurrent) {
        if (this.parent.clickable === true) {
          this.parent.goFar(this.index);
        }
      } else {
        const { index } = this;
        this.parent.onMainSlideClick({ index });
      }
    },
  },
};
</script>
