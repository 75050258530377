<template>
  <div class="carousel-3d-container" :style="{ height: slideHeight + 'px' }">
    <div class="carousel-3d-slider" :style="{ width: slideWidth + 'px', height: slideHeight + 'px' }">
      <slot />
    </div>
  </div>
</template>

<script>
import '@/css/carousel.scss';
const noop = () => {};

export default {
  name: "Carousel3d",
  props: {
    // 轮播图总数（不过好像不用这个，内部会自己计算）
    count: {
      type: [Number, String],
      default: 0,
    },
    // 轮播图视角数
    perspective: {
      type: [Number, String],
      default: 35,
    },
    // 设置每页展示的轮播图数量
    display: {
      type: [Number, String],
      default: 5,
    },
    // 是否开启无缝轮播（一般开启无缝轮播，配合 oneDirectional 属性）
    loop: {
      type: Boolean,
      default: true,
    },
    // 动画速度
    animationSpeed: {
      type: [Number, String],
      default: 500,
    },
    // 轮播图切换的方向（右边的图切换到左边）
    dir: {
      type: String,
      default: "rtl",
    },
    // 单张轮播图宽度
    width: {
      type: [Number, String],
      default: 360,
    },
    // 单张轮播图高度
    height: {
      type: [Number, String],
      default: 270,
    },
    // 边框宽度
    border: {
      type: [Number, String],
      default: 1,
    },
    // 轮播图之间的间距
    space: {
      type: [Number, String],
      default: "auto",
    },
    // 初始化展示的轮播图索引
    startIndex: {
      type: [Number, String],
      default: 0,
    },
    // 是否开启轮播图点击跳转功能
    clickable: {
      type: Boolean,
      default: true,
    },
    // 禁用3D效果
    disable3d: {
      type: Boolean,
      default: false,
    },
    // 最小移动距离，小于这个距离则不触发滑动事件
    minSwipeDistance: {
      type: Number,
      default: 10,
    },
    inverseScaling: {
      type: [Number, String],
      default: 300,
    },
    // 是否显示左右切换按钮
    controlsVisible: {
      type: Boolean,
      default: false,
    },
    // 左切换按钮的 HTML 内容
    controlsPrevHtml: {
      type: String,
      default: "&lsaquo;",
    },
    // 右切换按钮的 HTML 内容
    controlsNextHtml: {
      type: String,
      default: "&rsaquo;",
    },
    // 左右切换按钮的宽度
    controlsWidth: {
      type: [String, Number],
      default: 50,
    },
    // 左右切换按钮的高度
    controlsHeight: {
      type: [String, Number],
      default: 50,
    },
    // 达到最后一张轮播图时的回调 （不再使用 props 了，改成组件自定义事件了 @last-slide）
    onLastSlide: {
      type: Function,
      default: noop, // 空函数
    },
    // 新增 @before-slide-change 事件，轮播图切换前的回调
    // 轮播图切换后的回调 （改为自定义事件 @after-slide-change）
    onSlideChange: {
      type: Function,
      default: noop,
    },
    // 偏置（当左右轮播图数量不同时，多出的轮播图放左边或右边）
    bias: {
      type: String,
      default: "left",
    },
    // 点击中心主轮播图的回调
    onMainSlideClick: {
      type: Function,
      default: noop,
    },
    // 轮播图是否为单边的（初始化时只显示右侧轮播图，切换到最后一张时只显示左侧轮播图）
    oneDirectional: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      viewport: 0, // viewport 的宽度(slider container 的宽度)
      currentIndex: 0, // 当前显示的轮播图索引
      total: 0, // 轮播图总数
      dragStartX: 0, // 事件起始点的 X 坐标
      dragStartY: 0, // 事件起始点的 Y 坐标
      dragOffsetX: 0, // 水平拖动的距离
      dragOffsetY: 0, // 垂直拖动的距离
      mousedown: false, // 鼠标是否按下(屏幕是否被点击)
      zIndex: 998,
    };
  },
  computed: {
    /** 是否为最后一张轮播图 */
    isLastSlide() {
      return this.currentIndex === this.total - 1;
    },
    /** 是否为第一张轮播图 */
    isFirstSlide() {
      return this.currentIndex === 0;
    },
    /** 是否可以跳转到下一张轮播图（满足条件：不是最后一张或开启了循环滚动） */
    isNextPossible() {
      return !(!this.loop && this.isLastSlide);
    },
    /** 是否可以跳转到上一张轮播图（满足条件：不是第一张或开启了循环滚动） */
    isPrevPossible() {
      return !(!this.loop && this.isFirstSlide);
    },
    /** 单张轮播图宽度(加上了 2px 的边框) */
    slideWidth() {
      // viewport 的宽度(slider container 的宽度)
      const vw = this.viewport;
      // 临时变量，存储单张轮播图的宽度
      const sw = parseInt(this.width) + parseInt(this.border, 10) * 2;

      // 如果轮播图 container 的宽度小于单张轮播图的宽度，则使用轮播图 container 的宽度作为单张轮播图的宽度
      // process.browser 用于检查当前代码是否在浏览器环境中运行（而不是在服务器端）
      return vw < sw && process.browser ? vw : sw;
    },
    /** 单张轮播图高度(加上了 2px 的边框) */
    slideHeight() {
      const sw = parseInt(this.width, 10) + parseInt(this.border, 10) * 2;
      const sh = parseInt(parseInt(this.height) + this.border * 2, 10);
      // 图片原始尺寸的宽高比
      const aspectRatio = parseFloat(sw / sh);

      // 高度需要通过宽度和宽高比来计算，因为宽度可能是设定的宽度，也可能是 viewport 的宽度，所以需要动态计算高度
      return this.slideWidth / aspectRatio;
    },
    /** 首页可见的轮播图数量 */
    visible() {
      const v = this.display > this.total ? this.total : this.display;
      return v;
    },
    /** 是否有隐藏的轮播图 */
    hasHiddenSlides() {
      return this.total > this.visible;
    },
    /** 左侧轮播图索引数组 */
    leftIndices() {
      // n 表示左侧轮播图数量
      let n = (this.visible - 1) / 2;
      n = this.bias.toLowerCase() === "left" ? Math.ceil(n) : Math.floor(n);

      const indices = [];

      for (let i = 1; i <= n; i++) {
        indices.push(
          this.dir === "ltr" ? (this.currentIndex + i) % this.total : (this.currentIndex + this.total - i) % this.total
        );
      }

      return indices; // [-1, -2] [8, 7] 从中心往两边数
    },
    /** 右侧轮播图索引数组 */
    rightIndices() {
      // n 表示右侧轮播图数量
      let n = (this.visible - 1) / 2;
      n = this.bias.toLowerCase() === "right" ? Math.ceil(n) : Math.floor(n);

      const indices = [];

      for (let i = 1; i <= n; i++) {
        indices.push(
          this.dir === "ltr" ? (this.currentIndex + this.total - i) % this.total : (this.currentIndex + i) % this.total
        );
      }

      return indices; // [1, 2]
    },
    /** 最左侧多隐藏一张轮播图的索引 */
    leftOutIndex() {
      let n = (this.visible - 1) / 2;
      n = this.bias.toLowerCase() === "left" ? Math.ceil(n) : Math.floor(n);
      n++;

      if (this.dir === "ltr") {
        return (this.currentIndex + n) % this.total;
      } else {
        return (this.currentIndex + this.total - n) % this.total;
      }
    },
    /** 最右侧多隐藏一张轮播图的索引 */
    rightOutIndex() {
      let n = (this.visible - 1) / 2;
      n = this.bias.toLowerCase() === "right" ? Math.ceil(n) : Math.floor(n);
      n++;

      if (this.dir === "ltr") {
        return (this.currentIndex + this.total - n) % this.total;
      } else {
        return (this.currentIndex + n) % this.total;
      }
    },
  },
  watch: {
    count() {
      this.computeData();
    },
  },

  mounted() {
    if (!process.server) {
      this.computeData(true);
      this.attachMutationObserver();
      window.addEventListener("resize", this.setSize);

      // 这里的事件无需添加防抖，因为它们不是很耗时的操作
      // 如果当前设备支持 touch 事件
      if ("ontouchstart" in window) {
        this.$el.addEventListener("touchstart", this.handleMousedown);
        this.$el.addEventListener("touchend", this.handleMouseup);
        this.$el.addEventListener("touchmove", this.handleMousemove);
      } else {
        this.$el.addEventListener("mousedown", this.handleMousedown);
        this.$el.addEventListener("mouseup", this.handleMouseup);
        this.$el.addEventListener("mousemove", this.handleMousemove);
      }
    }
  },

  /** 组件卸载时移除事件监听器 */
  beforeDestroy() {
    // 浏览器环境
    if (!process.server) {
      this.detachMutationObserver();

      if ("ontouchstart" in window) {
        this.$el.removeEventListener("touchstart", this.handleMousedown);
        this.$el.removeEventListener("touchend", this.handleMouseup);
        this.$el.removeEventListener("touchmove", this.handleMousemove);
      } else {
        this.$el.removeEventListener("mousedown", this.handleMousedown);
        this.$el.removeEventListener("mouseup", this.handleMouseup);
        this.$el.removeEventListener("mousemove", this.handleMousemove);
      }

      window.removeEventListener("resize", this.setSize);
    }
  },
  methods: {
    /** 下一张 */
    goNext() {
      if (this.isNextPossible) {
        this.isLastSlide ? this.goSlide(0) : this.goSlide(this.currentIndex + 1);
      }
    },
    /** 上一张 */
    goPrev() {
      if (this.isPrevPossible) {
        this.isFirstSlide ? this.goSlide(this.total - 1) : this.goSlide(this.currentIndex - 1);
      }
    },
    /** 跳转到指定 index 的轮播图 */
    goSlide(index) {
      this.currentIndex = index < 0 || index > this.total - 1 ? 0 : index;

      if (this.isLastSlide) {
        if (this.onLastSlide !== noop) {
          console.warn("onLastSlide deprecated, please use @last-slide");
        }
        this.onLastSlide(this.currentIndex);

        this.$emit("last-slide", this.currentIndex);
      }

      this.$emit("before-slide-change", this.currentIndex);

      setTimeout(() => this.animationEnd(), this.animationSpeed);
    },
    /** 点击轮播图跳转(可一次跳多个) */
    goFar(index) {
      let diff = index - this.currentIndex;

      if (diff > this.visible / 2) {
        diff = index - this.total - this.currentIndex;
      }

      if (diff < -this.visible / 2) {
        diff = index + this.total - this.currentIndex;
      }

      const absDiff = Math.abs(diff);
      let timeBuff = 0;
      let i = 0;

      while (i < absDiff) {
        i += 1;
        // const timeout = (absDiff === 1) ? 0 : (timeBuff)
        const timeout = timeBuff;

        // 同时将多次跳转推入队列，每次跳转间隔的时间依次增加
        setTimeout(() => (diff < 0 ? this.goPrev() : this.goNext()), timeout);

        timeBuff += this.animationSpeed / absDiff;
      }
    },
    /** 动画结束后回调，触发 after-slide-change 事件 */
    animationEnd() {
      if (this.onSlideChange !== noop) {
        console.warn("onSlideChange deprecated, please use @after-slide-change");
      }
      this.onSlideChange(this.currentIndex);

      this.$emit("after-slide-change", this.currentIndex);
    },
    /** 鼠标按下事件（触摸开始事件） */
    handleMousedown(e) {
      if (!e.touches) {
        // PC 端阻止默认行为
        e.preventDefault();
      }

      this.mousedown = true;
      this.dragStartX = "ontouchstart" in window ? e.touches[0].clientX : e.clientX;
      this.dragStartY = "ontouchstart" in window ? e.touches[0].clientY : e.clientY;
    },
    /** 鼠标按下并移动 (触摸移动事件) */
    handleMousemove(e) {
      // 如果鼠标未按下或未点击，则不执行
      if (!this.mousedown) {
        return;
      }

      const eventPosX = "ontouchstart" in window ? e.touches[0].clientX : e.clientX;
      const eventPosY = "ontouchstart" in window ? e.touches[0].clientY : e.clientY;
      const deltaX = this.dragStartX - eventPosX;
      const deltaY = this.dragStartY - eventPosY;

      this.dragOffsetX = deltaX;
      this.dragOffsetY = deltaY;

      // If the swipe is more significant on the Y axis, do not move the slides because this is a scroll gesture
      // 如果滑动在 Y 轴上更显著，则不移动轮播图，因为这是一个滚动手势
      if (Math.abs(this.dragOffsetY) > Math.abs(this.dragOffsetX)) {
        return;
      }

      if (this.dragOffsetX > this.minSwipeDistance) {
        this.handleMouseup();
        this.goNext();
      } else if (this.dragOffsetX < -this.minSwipeDistance) {
        this.handleMouseup();
        this.goPrev();
      }
    },
    /** 鼠标抬起事件 （触摸抬起事件） */
    handleMouseup() {
      this.mousedown = false;
      this.dragOffsetX = 0;
      this.dragOffsetY = 0;
    },
    /** 监视 DOM 树中的变化 */
    attachMutationObserver() {
      const MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;

      if (MutationObserver) {
        const config = {
          attributes: true, // 观察所有监听的节点属性值的变化
          childList: true,
          characterData: true,
        };

        this.mutationObserver = new MutationObserver(() => {
          this.$nextTick(() => {
            this.computeData();
          });
        });

        if (this.$el) {
          this.mutationObserver.observe(this.$el, config);
        }
      }
    },
    /** 取消对 DOM 树的监听 */
    detachMutationObserver() {
      if (this.mutationObserver) {
        this.mutationObserver.disconnect();
      }
    },
    /** 获取轮播图数量 */
    getSlideCount() {
      if (this.$slots.default !== undefined) {
        return this.$slots.default.filter((value) => {
          return value.tag !== void 0;
        }).length;
      }

      return 0;
    },
    /** 计算轮播图总数，设置 currentIndex，计算 viewport 为轮播图 container 的宽度 */
    computeData(firstRun) {
      // 轮播图总数
      this.total = this.getSlideCount();
      // firstRun 表示是否是第一次运行
      // 如果是第一次运行或运行超过最后一张图
      if (firstRun || this.currentIndex >= this.total) {
        this.currentIndex = parseInt(this.startIndex) > this.total - 1 ? this.total - 1 : parseInt(this.startIndex);
      }

      // 将 viewport 设为当前元素的宽度，也就是整个轮播图 container 的宽度
      this.viewport = this.$el.clientWidth;
    },
    /** 页面窗口大小变化的回调 */
    setSize() {
      this.$el.style.cssText += "height:" + this.slideHeight + "px;";
      this.$el.childNodes[0].style.cssText +=
        "width:" + this.slideWidth + "px;" + " height:" + this.slideHeight + "px;";
    },
  },
};
</script>
